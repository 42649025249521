import { SaguaroTheme } from 'saguaro';
import { createGlobalStyle } from 'styled-components';

const CssReset = createGlobalStyle<{ theme: SaguaroTheme }>`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    width: 100%;
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;

export default CssReset;
